import Button from "./Button";
import ProjectCard from "./ProjectCard/ProjectCard";
import { scroll } from "./Scroll";
import "./Projects.scss";
import { useState, useEffect } from "react";

export default function Projects() {
    const [folders, setFolders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadFolders = async () => {
            try {
                // Загружаем список папок с timestamp
                const mainConfigUrl = `images/config.json?v=${Date.now()}`;
                const response = await fetch(mainConfigUrl);
                if (!response.ok) throw new Error('Не удалось загрузить список папок');
                const mainConfig = await response.json();
                
                // Загружаем конфигурацию для каждой папки с timestamp
                const folderPromises = mainConfig.folders.map(async folder => {
                    const folderConfigUrl = `images/${folder}/config.json?v=${Date.now()}`;
                    const folderResponse = await fetch(folderConfigUrl);
                    if (!folderResponse.ok) throw new Error(`Не удалось загрузить конфигурацию папки ${folder}`);
                    const config = await folderResponse.json();
                    return {
                        ...config,
                        folder,
                        timestamp: config.timestamp || mainConfig.timestamp
                    };
                });

                const loadedFolders = await Promise.all(folderPromises);
                setFolders(loadedFolders);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        loadFolders();
    }, []);

    if (loading) return <div>Загрузка...</div>;
    if (error) return <div>Ошибка: {error}</div>;

    function onClick() {
        scroll("services");
    }

    return (
        <div className="projects-wrapper" id="projects">
            <div className="projects-title">наши проекты</div>
            <div className="projects-cards">
                {folders.map((folder, index) => (
                    <ProjectCard
                        key={`${folder.folder}-${folder.timestamp}`}
                        folder={folder.folder}
                        name={folder.name}
                        year={folder.year}
                        thumbnail={folder.thumbnail}
                        images={folder.images}
                        timestamp={folder.timestamp}
                    />
                ))}
            </div>
            <Button colorScheme="white" onClick={onClick}>
                услуги
            </Button>
        </div>
    );
}
