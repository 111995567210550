import React, { useState, useRef } from "react";
import ImageGallery from 'react-image-gallery';
import classNames from "classnames";
import "react-image-gallery/styles/css/image-gallery.css";
import "./ProjectCard.scss";

export default function ProjectCard({ folder, name, year, thumbnail, images, timestamp }) {
  const [showGallery, setShowGallery] = useState(false);
  const [isFirstImageLoaded, setIsFirstImageLoaded] = useState(false);
  const firstImageRef = useRef(null);
  
  const yearStyle = classNames({
    "project-card-year": year,
    "project-card-year-clear": !year,
  });

  // Добавляем timestamp к URL изображения
  const getImageUrl = (image) => {
    return `images/${folder}/${image}?v=${timestamp}`;
  };

  const getThumbnailUrl = () => {
    return `images/${folder}/thumbnail/${thumbnail}?v=${timestamp}`;
  };

  // Подготавливаем данные для галереи
  const galleryImages = images.map(image => ({
    original: getImageUrl(image),
    thumbnail: getImageUrl(image)
  }));

  // Обработчик загрузки тумбнайла
  const handleThumbnailLoad = () => {
    // После загрузки тумбнайла начинаем загрузку первого изображения
    const preloadImage = new Image();
    preloadImage.onload = () => setIsFirstImageLoaded(true);
    preloadImage.src = images[0];
  };

  return (
    <div className="project-card-wrapper">
      <div className="project-card-title">{name}</div>
      <div>
        <div className={yearStyle}>{year}</div>
        {/* Тумбнайл */}
        <img
          src={getThumbnailUrl()}
          onClick={() => setShowGallery(true)}
          onLoad={handleThumbnailLoad}
          className="project-card-image"
          alt={name}
        />
        {/* Предзагрузка первого изображения */}
        {isFirstImageLoaded && (
          <div style={{ display: 'none' }}>
            <img ref={firstImageRef} src={images[0]} alt="" />
          </div>
        )}
        {/* Галерея */}
        {showGallery && (
          <div className="gallery-modal">
            <div className="gallery-overlay" onClick={() => setShowGallery(false)} />
            <div className="gallery-content">
              <button className="gallery-close" onClick={() => setShowGallery(false)}>×</button>
              <ImageGallery 
                items={galleryImages}
                showPlayButton={false}
                showFullscreenButton={false}
                showThumbnails={false}
                showNav={true}
                showBullets={false}
                autoPlay={false}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
